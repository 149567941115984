<template>
    <app-transition>
        <Dialog @close="$emit('close');">
            <v-row>
                <v-col class="NotAvailableToStartStreaming-message text-center">
                    <p>{{ difference ? $t('dialogs.not_available_to_start_streaming', {date, difference}) : $t('pages.user.setting.creator.stream.streamIsNotLive') }}</p>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog"

export default {
    name: "NoStreamsScheduledDialog",
    props: {
        date: {type: String, default: null},
        difference: {type: String, default: null}
    },
    components: {
        Dialog,
    }
}
</script>
<style scoped lang="scss">
.NotAvailableToStartStreaming {
    &-message {
        font-weight: bold;
        font-size: 20px;
    }
}

.v-btn {
    text-transform: capitalize;
    font-size: 1em !important;
    padding: 10px 28px !important;
}
</style>
